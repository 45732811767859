import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout2 from '../../components/layout2'
import Seo2 from '../../components/seo2'

const IndexPage = () => {
  return (
    <Layout2 pageTitle="Adventures">
      <p>Here are my adventures.</p></Layout2>
  )
}

export const Head = () => <Seo2 title="My Adventures" />

export default IndexPage